import { DynamicCardsGrid } from 'components/DynamicCardsGrid';
import type { CardDataType } from 'components/DynamicCardsGrid/types';
import type {
  GenericPage,
  Product,
  DynamicCardGrid as TDynamicCardGrid,
} from 'server/repository/hygraph/generated/graphqlTypes';
import { TrackingService } from 'service/tracking/trackingService';
import { ISharedTemplateProps } from 'templates/TemplateMapper/types';

type TAlgoliaItems = GenericPage | Product;

interface IDynamicCardGrids extends ISharedTemplateProps {
  templateProps: TDynamicCardGrid & {
    algoliaItems: TAlgoliaItems[];
  };
}

export const DynamicCardGrid = ({
  templateProps,
  templateIndex,
  primarySubBrandColor,
  difficultyCopy,
}: IDynamicCardGrids) => {
  const position = templateIndex ?? 0 + 1;
  const data = templateProps.algoliaItems;

  /**
   * Used to fire the teaser event for recipe cards in generic grid authorable
   */
  const clickTeaserRecipeListContainer = () => {
    TrackingService.clickTeaserRecipeList({
      containerTitle: templateProps?.heading,
      containerPosition: position,
      containerType: 'grid',
    });
  };

  return (
    data && (
      <DynamicCardsGrid
        data={templateProps as CardDataType}
        cardData={data as any}
        copyDictionary={difficultyCopy}
        showCards={templateProps.showCards}
        trackingEvents={{
          clickCTA: clickTeaserRecipeListContainer,
          clickCard: TrackingService.seeRecipeClick,
        }}
        host={''} // TODO: needs to be fixed
        ctaColor={primarySubBrandColor}
      />
    )
  );
};
